.card-title{
    font-size: 56px;
    text-align: center;
}
.card-text{
    text-align: center
}

.card-img{
    
}