.card{
    border: none;
    background-color: transparent;
    /* justify-content: center;
    text-align: center;
    align-content: center; */
    
}
h1 {
    justify-content: center;
    text-align: center;
    align-content: center;
}

#who{
    /* width: 700px; */
    text-align: center;
    justify-content: center;
}

#what{
    /* width: 700px; */
    text-align: center;
    justify-content: center;
}

#where{
    /* width: 700px; */
    text-align: center;
    justify-content: center;
}